// ListingCard.tsx
import { gql } from "@apollo/client";
import { CopyIcon, EditIcon, WarningIcon } from "@chakra-ui/icons";
import {
  Box,
  Flex,
  HStack,
  Image,
  LinkBox,
  LinkOverlay,
  ListItem,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Tag,
  Text,
  Tooltip,
  UnorderedList,
  VStack,
} from "@chakra-ui/react";
import { useState } from "react";
import { Link as ReactRouterLink } from "react-router-dom";
import noImage from "../assets/no-image.svg";
import type { ListingCard_ItemFragment } from "../graphql/generated/apollo";
import { filename } from "../utils/file";
import { platformKeyToName } from "../utils/platform";
import { CopiedItemForm } from "./CopiedItemForm";

gql`
  fragment ListingCard_Item on items {
    id
    name
    price
    images {
      path
      mime_type
    }
    status
    item_status {
      name
    }
    exhibitions {
      platform
      status
      error_message
    }
    variants {
      id
      name
      stock_quantity
      position
    }
  }
`;

type ListingCardProps = {
  item: ListingCard_ItemFragment;
};

export function ListingCard({ item }: ListingCardProps) {
  const GCS_BASE_URL = "https://storage.googleapis.com";
  let firstImageURL = noImage;
  if (item.images && item.images.length > 0) {
    const firstImagePath = `${item.images[0].path}/${filename("medium", item.images[0].mime_type)}`;
    firstImageURL = new URL(firstImagePath, GCS_BASE_URL).toString();
  }

  // モーダルの表示を制御するためのState
  const [isOpen, setIsOpen] = useState(false);

  const onClose = () => setIsOpen(false);
  const handleCopyClick = (event: React.MouseEvent) => {
    event.preventDefault(); // 親要素へのイベント伝播を防ぐ
    // コピー処理をここに書く
    setIsOpen(true);
  };

  return (
    <LinkBox
      borderRadius="lg"
      bg="white"
      color="gray.700"
      p="4"
      boxShadow="sm"
      _hover={{
        boxShadow: "md",
        color: "gray.700",
        ".chakra-link": { color: "gray.700" },
      }}
      width="full"
    >
      <VStack align="start" spacing="4">
        <Flex gap="4" direction="row" width="100%">
          <Box boxSize="64px">
            <Image src={firstImageURL} />
          </Box>
          <VStack align="start" overflow="hidden" flex="1">
            <LinkOverlay
              as={ReactRouterLink}
              to={/* `/items/${item.id}` // 商品詳細未実装のため */ `/sell/${item.id}`}
              _hover={{
                color: "gray.700",
                ".chakra-link": { color: "gray.700" },
              }}
            >
              <Text>{item.name}</Text>
            </LinkOverlay>
            <Text>
              {item.price.toLocaleString()}円{" "}
              {item.status !== "ACTIVE" && (
                <Tag colorScheme={item.status === "DRAFT" ? "blue" : "gray"}>{item.item_status?.name}</Tag>
              )}
            </Text>
            <Text textOverflow="ellipsis" whiteSpace="pre-wrap" noOfLines={2}>
              {item.variants
                .map((v) => (v.name ? `${v.name}:${v.stock_quantity}` : `在庫:${v.stock_quantity}`))
                .join(" / ") || "在庫なし"}
            </Text>
          </VStack>
          {item.exhibitions?.some((e) => e.status === "FAILED") ? (
            <Box zIndex={1}>
              <Tooltip
                label={
                  <Text>
                    {item.exhibitions
                      ?.filter((e) => e.status === "FAILED")
                      .map((e) => platformKeyToName(e.platform))
                      .join(",")}
                    への出品に失敗しました。
                    <br />
                    エラー詳細:
                    <UnorderedList>
                      {item.exhibitions
                        ?.filter((e) => e.status === "FAILED")
                        .map((e) => (
                          <ListItem key={e.platform}>
                            {platformKeyToName(e.platform)}: {e.error_message || "原因不明のエラー"}
                          </ListItem>
                        ))}
                    </UnorderedList>
                  </Text>
                }
                aria-label="exhibition-failure"
              >
                <WarningIcon color="red.500" />
              </Tooltip>
            </Box>
          ) : null}
          {item.exhibitions?.some((e) => e.status === "INCONSISTENT") ? (
            <Box zIndex={1}>
              <Tooltip
                label={`${item.exhibitions
                  ?.filter((e) => e.status === "INCONSISTENT")
                  .map((e) => platformKeyToName(e.platform))
                  .join(
                    ",",
                  )}のデータとラクダスのデータに食い違いが生じているため、在庫情報の同期ができません。ラクダス側で商品を更新していただくか、出品先の在庫情報を見直してください。`}
                aria-label="exhibition-failure"
              >
                <WarningIcon color="yellow.500" />
              </Tooltip>
            </Box>
          ) : null}
        </Flex>
        <HStack spacing="4" display="flex" alignSelf="flex-end">
          <ReactRouterLink to={`/sell/${item.id}`}>
            <EditIcon pos="relative" bottom="2px" right="2px" />
            <Text display="inline">編集</Text>
          </ReactRouterLink>
          <ReactRouterLink onClick={handleCopyClick} to="">
            <CopyIcon pos="relative" bottom="2px" right="2px" />
            <Text display="inline">コピー</Text>
          </ReactRouterLink>
        </HStack>
      </VStack>
      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader fontSize="md">商品情報をコピーして出品</ModalHeader>
          <ModalCloseButton />
          <ModalBody width="100%">
            <CopiedItemForm copiedItemId={item.id} onClose={onClose} />
          </ModalBody>
        </ModalContent>
      </Modal>
    </LinkBox>
  );
}
