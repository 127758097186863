import { Heading } from "@chakra-ui/react";
import ApplyForm from "../components/ApplyForm";

export function Apply() {
  return (
    <div className="App">
      <Heading as="h1" size="xl" marginBottom="24px" fontWeight="900" fontFamily="'Noto Sans JP', sans-serif">
        さあ、ネットショップを
        <br />
        ラクにしよう
      </Heading>
      <ApplyForm trialUser={false} onlyEnterprisePlan={false} />
    </div>
  );
}
